import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

class Footer extends React.Component {
  render() {
    const { token } = this.props;
    // const { reset, copy } = allowActions ?? { reset: true, copy: true };

    const frontVersion = `${process.env.REACT_APP_VERSION}`;

    var debugData = `${frontVersion && frontVersion + ' | '}CT => ${token}`;

    return (
      <footer className="app-footer">
        {frontVersion && `ver. ${frontVersion} `}
        <button
          className="copy-log"
          onClick={() => {
            navigator.clipboard.writeText(debugData);
          }}
        >
          <FontAwesomeIcon icon={faCopy} /> Copy debug info
        </button>
      </footer>
    );
  }
}

export default Footer;
