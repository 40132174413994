import axios from "axios";
import JSZip from "jszip";

const faceApiURL = process.env.REACT_APP_FACEAPI_URL;

const uploadDataToRemote = async (
  clientToken,
  endpoint,
  filename,
  filebuffer,
  callback
) => {
  var formData = new FormData();
  formData.append("token", clientToken);
  formData.append("files", filebuffer, filename);

  try {
    var req = await axios.post(`${faceApiURL}${endpoint}`, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data"
        // "X-AM-Domain": "aurum-mirror.pl"
      }
    });

    callback && callback();

    return req;
  } catch (e) {
    return false;
  }
};

const uploadPreviewPhoto = (clientToken, buffer, callback) => {
  return uploadDataToRemote(
    clientToken,
    `/upload-preview`,
    "preview.jpg",
    buffer,
    callback
  );
};

const uploadFrames = (clientToken, grabbedFrames, zipNumber) => {
  var zip = new JSZip();

  grabbedFrames.forEach(item => {
    zip.file(item.fileName, item.blob);
  });

  return zip.generateAsync({ type: "blob" }).then(function (content) {
    return uploadDataToRemote(
      clientToken,
      `/upload-frames`,
      `frames_${zipNumber}.zip`,
      content
    );
  });
};

export { uploadFrames, uploadPreviewPhoto };
