import React from "react";
import { Progress } from "semantic-ui-react";
import translate from "../helpers/translate";
import { preloadImage, sendMessageToParent } from "../helpers/utils";

const faceApiURL = process.env.REACT_APP_FACEAPI_URL;

class LoadingData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instruction: "Pracujemy nad Twoimi okularami. Zaczekaj chwilę...",
      progress: { working: false, state: 1, message: null },
      detectedFaceShape: props.detectedFaceShape || null,
      renderBoxSize: null
    };
  }

  componentDidMount() {
    if (this.props.context?.config?._pet === true) return;

    var renderBoxParent = document.getElementsByClassName(
        "Virtual-Mirror-Content"
      )[0],
      renderBoxHeight = renderBoxParent.offsetHeight,
      renderBoxWidth = renderBoxParent.offsetWidth;

    const renderBoxSize = Math.min(720, renderBoxHeight, renderBoxWidth);
    this.setState({
      renderBoxSize
    });

    if (this.props.waitingForPrepareProcessEnded === false) this.getData();
  }

  componentDidUpdate() {
    // console.log("componentDidUpdate", this.props.waitingForPrepareProcessEnded);
    this.props.waitingForPrepareProcessEnded === false &&
      this.state.progress.working === false &&
      this.getData();
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     this.props.waitingForPrepareProcessEnded !==
  //     nextProps.waitingForPrepareProcessEnded
  //   );
  // }

  setInstruction = instruction => {
    this.setState({
      instruction
    });
  };

  setPercent = (e, message) => {
    const data = JSON.parse(e.data);

    this.setState(prevState => ({
      progress: {
        ...prevState.progress,
        state: data.percent ? data.percent : prevState.progress.state,
        message
      }
    }));
  };

  getData = async () => {
    const { context, clientToken } = this.props,
      url = `${faceApiURL}/get/${clientToken}/${context.config.selectedFrame}`;

    try {
      // Zakończenie procesu bez pokazania panoram
      if (context?.config?._pet === "st") {
        const response = await fetch(`${url}?dispatch=1`);
        if (response.status === 204)
          return sendMessageToParent("PROCESS_ENDED", 1);
        else return this.showError();
      }

      const sse = new EventSource(url);

      this.setState(prevState => ({
        progress: {
          ...prevState.progress,
          working: true
        }
      }));

      sse.onerror = event => {
        // @todo - Error / obsłuzyć to
        console.log("/get [error]", event.data);

        sse.close();
        this.showError({
          type: "undefinedError",
          data: event.data
        });
      };

      sse.onmessage = event => {
        // @todo - Niezidentyfikowana wiadomość / obsłuzyć to
        console.log("/get [message]", event.data);
      };

      sse.addEventListener("apperror", async e => {
        sse.close();
        const data = JSON.parse(e.data),
          { m } = data;

        this.setState(prevState => ({
          progress: {
            ...prevState.progress,
            state: prevState.state,
            message: translate[m] ? translate[m] : m
          }
        }));
      });

      sse.addEventListener("fatalerror", async e => {
        sse.close();
        this.showError();
      });

      sse.addEventListener("init", async e => {
        this.setPercent(e, null);
      });

      sse.addEventListener("videoPrepare", async e => {
        this.setPercent(e, "Renderowanie wideo...");
      });

      sse.addEventListener("videoPrepareDone", async e => {
        this.setPercent(e, "...");
      });

      sse.addEventListener("renderFrames", async e => {
        this.setPercent(e, "Renderowanie klatek...");
      });

      sse.addEventListener("renderFramesDone", async e => {
        this.setPercent(e, "Zakończono renderowanie klatek...");
      });

      sse.addEventListener("renderPanoramas", async e => {
        this.setPercent(e, "Renderowanie panoram...");
      });

      sse.addEventListener("renderPanoramasDone", async e => {
        this.setPercent(e, "Oczekiwanie na dane...");
      });

      sse.addEventListener("finalData", async e => {
        sse.close();
        const data = JSON.parse(e.data);
        const { panoramas, center, shape } = data;

        if (!panoramas || panoramas.length === 0)
          return this.showError({
            m: "Brak wygenerowanych panoram"
          });

        Promise.all(panoramas.map(p => preloadImage(p.url))).then(() => {
          this.loadingSuccess({
            panoramas: panoramas,
            center: center,
            shape: this.state.detectedFaceShape || shape
          });
        });
      });
    } catch (e) {
      this.showError(e);
    }
  };

  /**
   * Informujemy rodzica, ze moze pokazać wyniki
   */
  loadingSuccess = data => {
    this.props.sendSignalToVirtualMirror("LOADING_DONE", data);
  };

  /**
   * Informujemy rodzica, ze moze pokazać błąd
   */
  showError = e => {
    console.log("loadingError", e);
    // Przerwanie działania aplikacji
    this.props.sendSignalToVirtualMirror("LOADING_ERROR", translate.default);
  };

  render() {
    const { clientPreviewImage, dimensions } = this.props;
    const { progress, detectedFaceShape, renderBoxSize } = this.state;

    // console.log("Loading", {
    //   height: dimensions.height,
    //   width: dimensions.width
    // });

    return (
      <div
        className={`visual-component`}
        style={{
          minHeight: "calc(100vh - 30px)"
        }}
      >
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            height: renderBoxSize, //dimensions.height,
            width: renderBoxSize //dimensions.width
          }}
        >
          {/* Show instructions */}
          {this.state.instruction && (
            <div
              style={{
                position: "absolute",
                zIndex: 99,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                height: "100%",
                maxWidth: dimensions.width,
                top: 0
              }}
            >
              <div
                style={{
                  maxWidth: this.state.width
                }}
                className="alert-on-top"
                role="alert"
              >
                {this.state.instruction}
              </div>
            </div>
          )}
          <>
            <div
              className="progress-box__canvas"
              style={{
                width: "100%",
                height: "100%"
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: `url(${clientPreviewImage})`,
                  backgroundSize: `${renderBoxSize}px ${renderBoxSize}px`
                }}
              ></div>
            </div>
          </>
          <div className="footer">
            <div className="progress-box__bar">
              <Progress percent={progress.state} />
              <div className="progress-box__bar__text progress-box__bar__text--bottom">
                {progress.message || "Trwa inicjalizacja..."}
                {detectedFaceShape && (
                  <span className="progress-box__bar__text--bottom progress-box__bar__text--right">
                    Wykryta twarz: {detectedFaceShape}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* END SCREEN */}
        </div>
      </div>
    );
  }
}

export default LoadingData;
